<router-outlet></router-outlet>
<p-toast key="yoda-toast">
  <ng-template let-message pTemplate="message">
    <span
      [class]="
        'p-toast-message-icon pi' + (message.icon ? ' ' + message.icon : '')
      "
      [ngClass]="{
        'pi-times-circle': message.severity === toastSeverity.info,
        'pi-exclamation-triangle': message.severity === toastSeverity.warn,
        'pi-info-circle': message.severity === toastSeverity.error,
        'fa-solid fa-circle-check': message.severity === toastSeverity.success
      }"
    >
    </span>
    <div class="p-toast-message-text">
      <div class="p-toast-summary">{{ message.summary }}</div>
      <div
        class="p-toast-detail"
        [ngClass]="{
          'yoda-detail-error': message.severity === toastSeverity.error
        }"
      >
        {{ message.detail }}
      </div>
      <div class="p-toast-action flex justify-content-between mt-2">
        <p
          class="yoda-timestamp m-0"
          [ngClass]="{
            'yoda-timestamp-success':
              message.severity === toastSeverity.success,
            'yoda-timestamp-error': message.severity === toastSeverity.error
          }"
        >
          {{ message.data.timestamp }}
        </p>
        <p
          class="yoda-action m-0"
          [ngClass]="{
            'yoda-action-success': message.severity === toastSeverity.success,
            'yoda-action-error': message.severity === toastSeverity.error
          }"
        >
          {{ message.data.action }}
        </p>
      </div>
    </div>
  </ng-template>
</p-toast>
