<div class="yoda-button">
  <button
    pButton
    pRipple
    [type]="type"
    [disabled]="disabled"
    class="p-button-secondary"
    [ngClass]="classButton()"
    (click)="onClick()"
  >
    <i *ngIf="icon" [ngClass]="icon"></i>{{ label }}
  </button>
</div>
