import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
//
import {
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { filter, map, Subject, takeUntil } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import { ThemeService } from '@theme/services/theme.service';
import { toastSeverity } from '@shared/models/toast.model';
import { Socket } from 'ngx-socket-io';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PermissionNotificationComponent } from '@shared/components/permission-notification/permission-notification.component';
@Component({
  selector: 'yoda-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'yoda';
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  toastSeverity = toastSeverity;
  permissionNotificationDialogRef: DynamicDialogRef | undefined;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private authServiceCore: AuthService,
    private _themeService: ThemeService,
    private socket: Socket,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    if (this.authServiceCore.getActiveAccount()) {
      !this.authServiceCore.userRole && this.openPermissionNotificationDialog();
      this.authServiceCore.getActiveAccount()?.idTokenClaims?.oid &&
        this.socket.emit(
          'profile',
          this.authServiceCore.getActiveAccount()?.idTokenClaims?.oid
        );
    }
    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe(() => {
        // console.log('ACCOUNT_ADDED || ACCOUNT_ADDED || ACCOUNT_ADDED');
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/experience-feed';
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this._themeService.setDarkTheme();

    // Approach 1
    // Only works if you actually do a redirect: next time, once logged in, you won't get your console message.
    // this.authService.handleRedirectObservable().subscribe((redirectResponse) => {
    //   if (redirectResponse != null) {
    //     // Acquire token silent success
    //     let accessToken = redirectResponse.accessToken;
    //     // Call your API with token
    //     console.log('We got the token! hahaha: ' + accessToken);
    //   }
    // });

    //
    // Approach 2
    // Made another try and figured out how to get the token. Make sure to use this code once the login processed has alredy been perfomed (for example if you're a page under MsalGuard).
    // const accessTokenRequest = {
    //   scopes: ['user.read'],
    //   account: this.authService.instance.getAllAccounts()[0],
    // };

    // this.authService.acquireTokenSilent(accessTokenRequest).subscribe((accessTokenReponse) => {
    //   if (accessTokenReponse != null) {
    //     // Acquire token silent success
    //     let accessToken = accessTokenReponse.accessToken;
    //     // Call your API with token
    //     // console.log('We got the token! hahaha - authService: ' + accessToken);
    //   }
    // });
    this.getMessage().subscribe((res: any) => {
      // console.log(res);
    });
  }

  sendMessage(msg: string) {
    this.socket.emit('message', msg);
  }

  getMessage() {
    return this.socket.fromEvent('message').pipe(map((data: any) => data.msg));
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      window.location.pathname = '/experience-feed';
    }
  }

  openPermissionNotificationDialog() {
    this.permissionNotificationDialogRef = this.dialogService.open(
      PermissionNotificationComponent,
      {
        header: 'Information',
        width: '480px',
        baseZIndex: 10000,
        closable: false,
        styleClass: 'permission-notification-dialog',
        maskStyleClass: 'permission-notification-dialog-mask',
        data: {
          content: 'An error has occurred. Please contact your administrator',
          isRefreshRequired: true,
        },
      }
    );
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
