export enum TypeClassButton {
  default,
  cancel,
  create,
  submit,
  previous,
  next,
  confirm,
  preview,
  submit_for_approval,
  save_draft,
  back,
  close_progress,
  remove,
}
// cancel, create, submit, normalDisabled, normalEnabled;

export enum TypeButton {
  submit = 'submit',
  reset = 'reset',
  button = 'button',
}
