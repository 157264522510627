import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TypeButton, TypeClassButton } from '@shared/models/button.model';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'yoda-permission-notification',
  templateUrl: './permission-notification.component.html',
  styleUrls: ['./permission-notification.component.scss'],
})
export class PermissionNotificationComponent implements OnInit {
  typeClassButton = TypeClassButton;
  typeButton = TypeButton;
  content!: string;
  isRefreshRequired!: boolean;
  isRedirectRequired = true;
  constructor(
    private router: Router,
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.content = this.dynamicDialogConfig.data?.content;
    this.isRefreshRequired = !!this.dynamicDialogConfig.data?.isRefreshRequired;
    this.dynamicDialogConfig.data?.isRedirectRequired === false
      ? (this.isRedirectRequired = false)
      : true;
  }

  onRedirectToHomePage() {
    if (this.isRedirectRequired) {
      this.dynamicDialogRef.close();
      this.router.navigate(['experience-feed']).then(() => {
        this.isRefreshRequired && window.location.reload();
      });
    } else {
      this.dynamicDialogRef.close();
    }
  }
}
