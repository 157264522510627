<div id="permission-notification">
  <div *ngIf="content" class="content">{{ content }}</div>
  <div class="controls">
    <yoda-button
      label="OK"
      [type]="typeButton.button"
      [typeClass]="typeClassButton.confirm"
      [disabled]="false"
      (clicked)="onRedirectToHomePage()"
    ></yoda-button>
  </div>
</div>
